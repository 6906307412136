/**
 * @param {Object} state - The current state of the application.
 *
 * @returns {boolean} Flag indicating whether the page is visible according to the
 *   Page Visibility API.
 */
const isPageVisible = state => state.misc.isPageVisible;

export { isPageVisible };

const UiSelectors = {
  isPageVisible,
};

export default UiSelectors;
