import gql from 'graphql-tag';
import React from 'react';

import { setConnections } from '../../../state/actions/connections';
import { setPopularSessionLocations } from '../../../state/actions/sessionLocations';

import connections from './connections';
import HomePage from './HomePage';

const HomePageDataQuery = gql`
  query homePageData {
    popularSessionLocations {
      favorite
      id
      img {
        alt
        src
      }
      link {
        to
      }
      titleMsgId
    }
  }
`;

async function action(context) {
  const {
    graphqlClient,
    store: { dispatch },
  } = context;
  const errors = [];
  try {
    const result = await graphqlClient.query({
      query: HomePageDataQuery,
    });
    dispatch(setPopularSessionLocations(result.data.popularSessionLocations));

    // TODO: Pull data from database instead of from JS modules.
    dispatch(setConnections(connections));
  } catch (err) {
    errors.push(err);
  }

  return {
    title: 'Shadow others and let people shadow you',
    description:
      'Find experts to shadow in real life and gain hands-on training, real-world knowledge and expert instruction.',
    chunks: ['initial'],
    component: (
      <HomePage className="page--home" errors={errors} look="secondary" />
    ),
  };
}

export default action;
