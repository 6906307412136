import React from 'react';
import ContentLoader from 'react-content-loader';

const AreaOfExpertiseBoxLoader = props => (
  <ContentLoader
    height={116}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    speed={4}
    width={158}
    {...props}
  >
    <rect height="92" rx="5" ry="5" width="150" x="4" y="4" />
    <rect height="8" rx="3" ry="3" width="92" x="32" y="104" />
  </ContentLoader>
);

export default AreaOfExpertiseBoxLoader;
