import PTs from 'prop-types';

import ImgShape from './ImgShape';

const ExpertProfileSummaryShape = PTs.shape({
  displayName: PTs.string.isRequired,
  firstName: PTs.string.isRequired,
  lastName: PTs.string.isRequired,
  profilePhoto: ImgShape,
});

export default ExpertProfileSummaryShape;
