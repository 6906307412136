/* eslint-disable import/prefer-default-export */

import { SET_CONNECTIONS } from './';

/**
 * @param {Connection[]} connections - The connections to display on relevant pages.
 */
export function setConnections(connections) {
  return {
    type: SET_CONNECTIONS,
    payload: {
      connections,
    },
  };
}
