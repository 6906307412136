import { formatUsd } from '../../lib/FormatUtils';

const CostRangeUsd = ({ costRange }) => {
  if (costRange.min === costRange.max) {
    return formatUsd(costRange.min);
  }
  return `${formatUsd(costRange.min)} - ${formatUsd(costRange.max)}`;
};

export default CostRangeUsd;
