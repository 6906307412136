exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TlsK{margin:10px;text-align:center}.PqjNe{width:100%;height:100%;background:-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));background:linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));position:absolute;top:0;left:0;border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);-webkit-transition:.3s ease;transition:.3s ease}.LTS4P{max-width:100%;max-height:400px;border-radius:var(--base--border-radius);border-radius:var(--base--border-radius)}.dV-mT{margin-top:5px;font-size:16px;font-size:1rem;text-align:center}._2uksZ{position:absolute;z-index:10;bottom:0;left:50%;-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);transform:translate(-50%, -50%);color:#fff;font-size:22px;width:90%;-webkit-transition:.5s ease;transition:.5s ease}._5P_sD{position:relative;display:block}", ""]);

// exports
exports.locals = {
	"root": "_1TlsK",
	"overlay": "PqjNe",
	"img": "LTS4P",
	"text": "dV-mT",
	"title": "_2uksZ",
	"link": "_5P_sD"
};