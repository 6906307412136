import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ErrorBox from '../../../components/ErrorBox';
import ExpertBox from '../../../components/ExpertBox';
import AreaOfExpertiseBox from '../../../components/AreaOfExpertiseBox';
import Link from '../../../components/Link';
import PageLayout from '../../../components/PageLayout';
// import SessionLocationBox from '../../../components/SessionLocationBox';
import ShadowerLink from '../../../components/ShadowerLink';
import ShadowingSummaryBoxGrid from '../../../components/ShadowingSummaryBoxGrid';

import AreaOfExpertiseBoxLoader from '../../../components/Loader/AreaOfExpertiseBoxLoader';
import CircleLoader from '../../../components/Loader/CircleLoader';
import ExpertBoxLoader from '../../../components/Loader/ExpertBoxLoader';
import ShadowingBoxGridLoader from '../../../components/Loader/ShadowingBoxGridLoader';

import LogInToUpdateFavorites from '../../../components/Messages/LogInToUpdateFavorites';

import config from '../../../config/client-config';

import getAreasOfExpertiseQuery from '../../../queries/getAreasOfExpertiseQuery';

import { updateFavorite } from '../../../state/actions/AuthenticatedUserActions';

import UiSelectors from '../../../state/selectors/UiSelectors';
import { getUserProfile } from '../../../state/selectors/UserSelectors';

import {
  Col,
  Grid,
  Row,
} from '../../../incubation/components/ResponsiveGridLayout';

import ConnectionShape from '../../../lib/ConnectionShape';
import { toExpertCountText } from '../../../lib/DataUtils';
import ErrorShape from '../../../lib/ErrorShape';
import { formatUsd } from '../../../lib/FormatUtils';

// import Json from '../../../lib/Json';
// import SessionLocationShape from '../../../lib/SessionLocationShape';

import messages from '../../../locale/messages';

import getPopularNearbyShadowingsQuery from '../../../queries/getPopularNearbyShadowingsQuery';
import getTopRatedExpertsNearbyQuery from '../../../queries/getTopRatedExpertsNearbyQuery';

import ConnectionBox from './ConnectionBox';
import opportunities_by_skill_level from './opportunities_by_skill_level';

import s from './HomePage.scss';

function HomePage(allProps) {
  const { className, look, ...props } = allProps;
  return (
    <PageLayout className={cx(className, s.page)} look={look}>
      <HomePageMainContent {...props} />
    </PageLayout>
  );
}

class HomePageMainContent extends React.Component {
  static propTypes = {
    connections: PTs.arrayOf(ConnectionShape.isRequired).isRequired,
    errors: PTs.arrayOf(ErrorShape.isRequired),
    intl: intlShape.isRequired,
    isPageVisible: PTs.bool.isRequired,
    onFavorite: PTs.func.isRequired,
    userAccount: PTs.shape({
      lat: PTs.number,
      lng: PTs.number,
      userId: PTs.string.isRequired,
    }),
    // popularSessionLocations: PTs.arrayOf(SessionLocationShape.isRequired)
    // .isRequired,
  };

  static defaultProps = {
    errors: [],
    userAccount: null,
  };

  state = {
    exploreByCategorySectionInView: false,
    showPopularNearbyShadowingSection: false,
    showTopRatedExpertsNearbySection: false,
    ipLocation: null,
    lat: null,
    lng: null,
  };

  setExploreByCategorySectionInView = () => {
    this.setState({
      exploreByCategorySectionInView: true,
    });
  };

  setExploreByCategorySectionOutOfView = () => {
    this.setState({
      exploreByCategorySectionInView: false,
    });
  };

  showPopularNearbyShadowingSection = () => {
    /* this.setState({
      showPopularNearbyShadowingSection: true,
    });*/
  };

  showTopRatedExpertsNearbySection = () => {
    // Placing IP Geolocation API request here.
    // It's in the waypoint function so that we don't make unecessary requests for every user that goes to the homepage
    // I also decided not to put it in the showPopularNearbyShadowingSection so that we make fewer requests to ipstack.com
    // This could cause it to not have access to the location data so I removed the setState to here.

    fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(data => {
        // 1 degree lat or lng is approx 69 miles
        this.setState({
          ipLocation: data,
          lat: {
            gte: data.latitude - (1 / 69) * 100,
            lte: data.latitude + (1 / 69) * 100,
          },
          lng: {
            gte: data.longitude - (1 / 69) * 100,
            lte: data.longitude + (1 / 69) * 100,
          },
          showTopRatedExpertsNearbySection: true,
          showPopularNearbyShadowingSection: true,
        });
      });
  };

  renderErrorsSection() {
    const { errors } = this.props;
    return (
      <section className={s.errorsSection}>
        <Grid>
          <Row>
            <Col xs={12}>
              {errors.map(error => (
                <ErrorBox key={error.message} message={error} />
              ))}
            </Col>
          </Row>
        </Grid>
      </section>
    );
  }

  render() {
    const {
      connections,
      errors,
      intl,
      isPageVisible,
      onFavorite,
      // popularSessionLocations,
      userAccount,
    } = this.props;

    const {
      exploreByCategorySectionInView,
      showPopularNearbyShadowingSection,
      showTopRatedExpertsNearbySection,
      ipLocation,
      lat,
      lng,
    } = this.state;

    return (
      <React.Fragment>
        {errors && errors.length > 0 && this.renderErrorsSection()}
        <div className={s.backgroundImage}>
          <div className={s.waves} />
        </div>

        <section className={s.headSection}>
          <Grid>
            <Row>
              <Col xs={12}>
                <p className={s.slogan}>Education Reimagined</p>
                <h2 className={s.subTitle}>Skill shadowing in real life</h2>
                {!userAccount && (
                  <ShadowerLink
                    className={cx(s.getStarted, '-e2e-get-started-link')}
                    to="/register"
                  >
                    Get started
                  </ShadowerLink>
                )}
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.learnMoreSection}>
          <Grid>
            <Row>
              <Col>
                {/* <img
                  className={s.popout}
                  src="/assets/images/home/icons/logo-dark.svg"
                  alt="Logo"
                />*/}
                {/*  <h2
                  className={cx(
                    'globalCardHeading',
                    'text-align-center',
                    'globalBrandColor',
                  )}
                >
                  Limitless learning awaits
                </h2> */}
                <p className={s.mission}>
                  Our mission is to reimagine how education works in an age when
                  experience and connections matter most.
                </p>

                <div className={s.features}>
                  <div className={s.feature}>
                    <img
                      alt="knowledge"
                      src="/assets/images/home/icons/knowledge.svg"
                    />

                    <h4
                      className={cx(
                        'globalCardHeading',
                        'text-align-center',
                        s.instructionTitle,
                      )}
                    >
                      Real-world knowledge
                    </h4>
                    <p className={cx('globalParagraph', 'text-align-center')}>
                      Gain real-world experience shadowing experts on the job at
                      their place of work.
                    </p>
                  </div>
                  <div className={s.feature}>
                    <img
                      alt="training"
                      src="/assets/images/home/icons/training.svg"
                    />

                    <h4
                      className={cx(
                        'globalCardHeading',
                        'text-align-center',
                        s.instructionTitle,
                      )}
                    >
                      Hands-on training
                    </h4>
                    <p className={cx('globalParagraph', 'text-align-center')}>
                      Learn how to operate the tools, software and equipment
                      that professionals use every day.
                    </p>
                  </div>
                  <div className={s.feature}>
                    <img
                      alt="instruction"
                      src="/assets/images/home/icons/instruction.svg"
                    />
                    <h4
                      className={cx(
                        'globalCardHeading',
                        'text-align-center',
                        s.instructionTitle,
                      )}
                    >
                      Expert instruction
                    </h4>
                    <p className={cx('globalParagraph', 'text-align-center')}>
                      Receive immediate feedback, analysis, critiques,
                      explanation and assistance.
                    </p>
                  </div>
                </div>
                <div className={s.learnMoreLinks}>
                  <ShadowerLink className={s.link} look="outline" to="/about">
                    How it works
                  </ShadowerLink>
                  {!userAccount && (
                    <ShadowerLink className={s.link} to="/register">
                      Sign up for free
                    </ShadowerLink>
                  )}
                </div>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.limitlessLearningSection}>
          <Grid>
            <Row>
              <Col>
                <h2 className="globalSecondaryHeading">
                  Limitless learning awaits
                </h2>
                <p className={s.connectionsText}>
                  Grow your network, gain experience and share knowledge within
                  your local community
                </p>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.makeConnectionsSection}>
          {/* <div className={s.waves} />*/}
          <Grid>
            <Row>
              <Col>
                {/*   <img
                  className={s.popoutAlt}
                  src="/assets/images/home/icons/make-connections.svg"
                  alt="Logo"
                />*/}
                <div className={s.connectionsContainer}>
                  <ul className={s.connection} role="presentation">
                    {connections.map(connection => (
                      <li key={connection.key}>
                        <ConnectionBox connection={connection} />
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>

        <Waypoint
          onEnter={this.setExploreByCategorySectionInView}
          onLeave={this.setExploreByCategorySectionOutOfView}
          scrollableAncestor="window"
        >
          <section className={s.exploreByCategorySection}>
            <Grid>
              <Row>
                <Col>
                  <Query
                    pollInterval={
                      isPageVisible && exploreByCategorySectionInView
                        ? 73 * 1000
                        : 0 // Turn polling off
                    }
                    query={getAreasOfExpertiseQuery}
                    ssr={false}
                  >
                    {queryResult => {
                      const { data, error, loading } = queryResult;

                      const loadingWithData = !!(
                        loading &&
                        data &&
                        data.expertiseCategories
                      );
                      const loadingWithoutData = loading && !loadingWithData;

                      const elements = [];

                      let busy = null;
                      if (loadingWithData) {
                        busy = (
                          <CircleLoader
                            className="pull-right"
                            show
                            size={16}
                            style={{ margin: '8px' }}
                          />
                        );
                      }

                      elements.push(
                        <React.Fragment key="header">
                          {/*    <img
                            className={s.popout}
                            src="/assets/images/home/icons/explore-by-category.svg"
                            alt="Logo"
                          />*/}
                          <h2 className="globalSectionHeading">
                            Explore by category
                            {busy}
                          </h2>
                        </React.Fragment>,
                      );

                      if (error) {
                        elements.push(
                          <ErrorBox
                            key="error"
                            message="Something went wrong while loading categories."
                          />,
                        );
                      }

                      if (loadingWithoutData) {
                        elements.push(
                          <ul key="loading" role="presentation">
                            <li>
                              <AreaOfExpertiseBoxLoader />
                            </li>
                            <li>
                              <AreaOfExpertiseBoxLoader />
                            </li>
                            <li>
                              <AreaOfExpertiseBoxLoader />
                            </li>
                            <li>
                              <AreaOfExpertiseBoxLoader />
                            </li>
                          </ul>,
                        );
                      }

                      if (data && data.expertiseCategories) {
                        const { edges, totalCount } = data.expertiseCategories;
                        const displayCount = edges.length;
                        elements.push(
                          <React.Fragment key="data">
                            <ul role="presentation">
                              {edges
                                .map(catEdge => ({
                                  favorite: catEdge.favorite,
                                  id: catEdge.node.id,
                                  img: catEdge.node.img,
                                  link: {
                                    to: `shadowings/areas-of-expertise/${
                                      catEdge.node.slug
                                    }/`,
                                  },
                                  text: toExpertCountText(catEdge.expertCount),
                                  title: intl.formatMessage(
                                    messages[catEdge.node.nameMsgId],
                                  ),
                                }))
                                .map(aoe => (
                                  <li key={aoe.id}>
                                    <AreaOfExpertiseBox areaOfExpertise={aoe} />
                                  </li>
                                ))}
                            </ul>
                            <Link
                              className={s.showAllLink}
                              to="/shadowings/areas-of-expertise"
                            >
                              {totalCount > displayCount ? (
                                <span className={s.text}>
                                  Show all ({totalCount})
                                </span>
                              ) : (
                                <span className={s.text}>Show all</span>
                              )}
                              &nbsp;
                              <FontAwesomeIcon icon={faAngleRight} />
                            </Link>
                          </React.Fragment>,
                        );
                      }
                      return elements;
                    }}
                  </Query>
                </Col>
              </Row>
            </Grid>
          </section>
        </Waypoint>

        {/* <section className={s.exploreByLocationSection}>
          <Grid>
            <Row>
              <Col>
                <h2>Explore by location</h2>
                <ul role="presentation">
                  {popularSessionLocations
                    .map(loc => ({
                      favorite: loc.favorite,
                      id: loc.id,
                      img: loc.img,
                      link: loc.link,
                      title: intl.formatMessage(messages[loc.titleMsgId]),
                    }))
                    .map(loc => (
                      <li key={loc.id}>
                        <SessionLocationBox sessionLocation={loc} />
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </Grid>
        </section>*/}

        <Waypoint
          onEnter={this.showTopRatedExpertsNearbySection}
          scrollableAncestor="window"
        >
          <section className={s.topRatedExpertsNearbySection}>
            <Grid>
              <Row>
                <Col>
                  {showTopRatedExpertsNearbySection && (
                    <Query
                      fetchPolicy="cache-and-network"
                      query={getTopRatedExpertsNearbyQuery}
                      ssr={false}
                      variables={{
                        limit: 4,
                        predicates: { isExpert: true, lat, lng },
                      }}
                    >
                      {queryResult => {
                        const { data, error, loading } = queryResult;

                        const loadingWithData = !!(
                          loading &&
                          data &&
                          data.findExperts
                        );
                        const loadingWithoutData = loading && !loadingWithData;

                        const elements = [];

                        let busy = null;
                        if (loadingWithData) {
                          busy = (
                            <CircleLoader
                              className="pull-right"
                              show
                              size={16}
                              style={{ margin: '8px' }}
                            />
                          );
                        }

                        elements.push(
                          <React.Fragment key="header">
                            {/*  <img
                              className={s.popout}
                              src="/assets/images/home/icons/top-rated-experts-nearby.svg"
                              alt="Logo"
                            />*/}
                            {ipLocation ? (
                              <h2 className="globalSectionHeading">
                                <>
                                  {`Qualified experts near ${ipLocation.city}`}
                                  {busy}
                                </>
                              </h2>
                            ) : (
                              <h2 className="globalSectionHeading">
                                Qualified experts nearby
                                {busy}
                              </h2>
                            )}
                          </React.Fragment>,
                        );

                        if (error) {
                          elements.push(
                            <ErrorBox
                              key="error"
                              message="Something went wrong while searching for experts."
                            />,
                          );
                        }

                        if (loadingWithoutData) {
                          elements.push(
                            <ul key="loading" role="presentation">
                              <li>
                                <ExpertBoxLoader />
                              </li>
                            </ul>,
                          );
                        }

                        if (data && data.findExperts) {
                          const { edges, totalCount } = data.findExperts;
                          elements.push(
                            <React.Fragment key="data">
                              {edges.length > 0 ? (
                                <ul role="presentation">
                                  {edges.map(edge => (
                                    <li key={edge.node.userId}>
                                      <ExpertBox
                                        distanceMi={edge.distance}
                                        expert={edge.node}
                                        favorite={!!edge.favorite}
                                        favoriteQueryVariables={{
                                          limit: 4,
                                          predicates: {
                                            isExpert: true,
                                            lat,
                                            lng,
                                          },
                                        }}
                                        onFavorite={onFavorite}
                                      />
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>
                                  Currently, there are no experts nearby. Please
                                  check back soon.
                                </p>
                              )}
                              {totalCount > 0 && (
                                <Link className={s.showAllLink} to="/users">
                                  <span className={s.text}>
                                    Show all ({totalCount})
                                  </span>
                                  &nbsp;
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </Link>
                              )}
                            </React.Fragment>,
                          );
                        }
                        return elements;
                      }}
                    </Query>
                  )}
                </Col>
              </Row>
            </Grid>
          </section>
        </Waypoint>

        <Waypoint
          onEnter={this.showPopularNearbyShadowingSection}
          scrollableAncestor="window"
        >
          <section className={s.popularNearbyShadowingSection}>
            <Grid>
              <Row>
                <Col>
                  {showPopularNearbyShadowingSection && (
                    <Query
                      fetchPolicy="cache-and-network"
                      query={getPopularNearbyShadowingsQuery}
                      ssr={false}
                      variables={{
                        limit: 4,
                        predicates: { lat, lng },
                      }}
                    >
                      {queryResult => {
                        const { data, error, loading } = queryResult;

                        const loadingWithData = !!(
                          loading &&
                          data &&
                          data.findExpertProfiles
                        );
                        const loadingWithoutData = loading && !loadingWithData;

                        const elements = [];

                        let busy = null;
                        if (loadingWithData) {
                          busy = (
                            <CircleLoader
                              className="pull-right"
                              show
                              size={16}
                              style={{ margin: '8px' }}
                            />
                          );
                        }

                        elements.push(
                          <React.Fragment key="header">
                            {/*  <img
                              className={s.popoutAlt}
                              src="/assets/images/home/icons/popular-shadowing-nearby.svg"
                              alt="Logo"
                            />*/}
                            {ipLocation ? (
                              <h2 className="globalSectionHeading">
                                <>
                                  {`Popular shadowing near ${ipLocation.city}`}
                                  {busy}
                                </>
                              </h2>
                            ) : (
                              <h2 className="globalSectionHeading">
                                Popular shadowing nearby
                                {busy}
                              </h2>
                            )}
                          </React.Fragment>,
                        );

                        if (error) {
                          elements.push(
                            <ErrorBox
                              key="error"
                              message="Something went wrong while searching for nearby shadowing opportunities."
                            />,
                          );
                        }

                        if (loadingWithoutData) {
                          elements.push(
                            <ShadowingBoxGridLoader key="loading" count={4} />,
                          );
                        }

                        if (data && data.findExpertProfiles) {
                          const appRoot = config.app.root;
                          const {
                            edges /* , totalCount */,
                          } = data.findExpertProfiles;
                          const shadowingSummaries = edges.map(edge => {
                            const {
                              distance,
                              favorite,
                              node,
                              ratingInfo,
                            } = edge;
                            const {
                              expert,
                              id,
                              name,
                              slug,
                              upcomingSessionsInfo,
                              media,
                            } = node;

                            const images = media.filter(
                              md => md.mediaType === 'image',
                            );

                            const { costRangeUsd } = upcomingSessionsInfo;
                            let pricePerSession;
                            if (costRangeUsd) {
                              if (costRangeUsd.min === costRangeUsd.max) {
                                pricePerSession = `${formatUsd(
                                  costRangeUsd.max,
                                )} per session`;
                              } else {
                                pricePerSession = `${formatUsd(
                                  costRangeUsd.min,
                                )} - ${formatUsd(
                                  costRangeUsd.max,
                                )} per session`;
                              }
                            }

                            return {
                              id,
                              distance,
                              expert,
                              favorite,
                              img: {
                                alt: name,
                                src: images[0]
                                  ? images[0].mediaUrl
                                  : `${appRoot}assets/images/shadowing/shadowing@1280x800.jpg`,
                              },
                              link: {
                                to: `${appRoot}shadowings/~${slug}/`,
                              },
                              pricePerSession,
                              ratingInfo,
                              title: name,
                            };
                          });
                          elements.push(
                            <React.Fragment key="data">
                              {shadowingSummaries.length > 0 ? (
                                <React.Fragment>
                                  <ShadowingSummaryBoxGrid
                                    favoriteQueryVariables={{
                                      limit: 4,
                                      predicates: { lat, lng },
                                    }}
                                    onFavorite={onFavorite}
                                    shadowingSummaries={shadowingSummaries}
                                  />
                                  {/* totalCount > 0 && (
                                    <Link
                                      className={s.showAllLink}
                                      to="shadowing/ratings/top/locations/fort-collins/"
                                    >
                                      <span className={s.text}>
                                        Show all ({totalCount})
                                      </span>
                                      &nbsp;
                                      <FontAwesomeIcon icon={faAngleRight} />
                                    </Link>
                                  )*/}
                                </React.Fragment>
                              ) : (
                                <p>
                                  Currently, there are no shadowing
                                  opportunities nearby.
                                </p>
                              )}
                            </React.Fragment>,
                          );
                        }
                        return elements;
                      }}
                    </Query>
                  )}
                </Col>
              </Row>
            </Grid>
          </section>
        </Waypoint>

        <section className={s.opportunitiesBySkillLevelSection}>
          <Grid>
            <Row>
              <Col>
                <h2 className="globalSectionHeading">
                  Opportunities for any level of skill
                </h2>
                <div className={s.skillLevelList}>
                  <section className={s.skillLevelSection}>
                    <img
                      alt="novice"
                      className={s.skillLevelImg}
                      src="/assets/images/home/novice@640x400.jpg"
                    />
                    <div className={s.opportunities}>
                      <h3
                        className={cx('globalCardHeading', 'text-align-center')}
                      >
                        Novice
                      </h3>
                      <ul>
                        {opportunities_by_skill_level.novice.map(
                          opportunity => (
                            <li key={opportunity.id} className={s.opportunity}>
                              <span className={s.opportunityName}>
                                {opportunity.name}
                              </span>
                            </li>
                          ),
                        )}
                      </ul>
                      <Link className={s.opportunityLink} to="/help">
                        Learn more &nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Link>
                    </div>
                  </section>
                  <section className={s.skillLevelSection}>
                    <img
                      alt="amateur"
                      className={s.skillLevelImg}
                      src="/assets/images/home/amateur@640x400.jpg"
                    />
                    <div className={s.opportunities}>
                      <h3
                        className={cx('globalCardHeading', 'text-align-center')}
                      >
                        Amateur
                      </h3>
                      <ul>
                        {opportunities_by_skill_level.amateur.map(
                          opportunity => (
                            <li key={opportunity.id} className={s.opportunity}>
                              <span className={s.opportunityName}>
                                {opportunity.name}
                              </span>
                            </li>
                          ),
                        )}
                      </ul>
                      <Link className={s.opportunityLink} to="/help">
                        Learn more &nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Link>
                    </div>
                  </section>
                  <section className={s.skillLevelSection}>
                    <img
                      alt="expert"
                      className={s.skillLevelImg}
                      src="/assets/images/home/expert@640x400.jpg"
                    />
                    <div className={s.opportunities}>
                      <h3
                        className={cx('globalCardHeading', 'text-align-center')}
                      >
                        Expert
                      </h3>
                      <ul>
                        {opportunities_by_skill_level.expert.map(
                          opportunity => (
                            <li key={opportunity.id} className={s.opportunity}>
                              <span className={s.opportunityName}>
                                {opportunity.name}
                              </span>
                            </li>
                          ),
                        )}
                      </ul>
                      <Link className={s.opportunityLink} to="/handbook">
                        View the Handbook &nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Link>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className={s.readyToGetStartedSection}>
          <Grid>
            <Row>
              <Col>
                <div className={s.halfLayout}>
                  <div>
                    {!userAccount ? (
                      <h3 className="globalSecondaryHeading">
                        Ready to get started?
                      </h3>
                    ) : (
                      <h3 className="globalSecondaryHeading">Need help?</h3>
                    )}
                    {!userAccount ? (
                      <p className={s.readyText}>
                        Sign up today to begin transforming education.
                      </p>
                    ) : (
                      <p className={s.readyText}>
                        Contact us with questions, issues or just to say hi!
                      </p>
                    )}
                  </div>

                  <div>
                    {!userAccount && (
                      <ShadowerLink className={s.readyLink} to="/register">
                        Create an account
                      </ShadowerLink>
                    )}
                    <ShadowerLink
                      look="inverse"
                      to="https://tawk.to/chat/5cb3c345d6e05b735b4288a3/default"
                    >
                      Contact us
                    </ShadowerLink>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>
        {/* <section className={s.bottomSection}>
          <img src="/assets/images/home/artist@1280x800.jpg" alt="artist" />
        </section>*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  connections: state.connections.connections,
  isPageVisible: UiSelectors.isPageVisible(state),
  popularSessionLocations: state.sessionLocations.popular.collection,
  userAccount: getUserProfile(state),
});

const mapDispatchToProps = dispatch => ({
  onFavorite: (type, data, queryVariables) => {
    const loginModalMessage = <LogInToUpdateFavorites />;
    dispatch(updateFavorite(type, data, queryVariables, loginModalMessage));
  },
});

const ReduxAwareHomePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);

export default injectIntl(withStyles(s)(ReduxAwareHomePage));
