import React from 'react';
import ContentLoader from 'react-content-loader';

const ShadowingBoxLoader = props => (
  <ContentLoader
    height={200}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    speed={4}
    width={200}
    {...props}
  >
    <rect height="100" rx="8" ry="8" width="184" x="8" y="8" />
    <rect height="14" rx="4" ry="4" width="140" x="8" y="120" />
    <rect height="12" rx="3" ry="3" width="92" x="8" y="146" />
    <rect height="12" rx="3" ry="3" width="90" x="8" y="168" />
  </ContentLoader>
);

export default ShadowingBoxLoader;
