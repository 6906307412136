import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import PageLayout from '../../../components/PageLayout';

import {
  Col,
  Grid,
  Row,
} from '../../../incubation/components/ResponsiveGridLayout';

import s from './NotFoundPage.scss';

class NotFoundPage extends React.Component {
  static propTypes = {
    title: PTs.string.isRequired,
  };

  render() {
    const { title } = this.props;
    return (
      <PageLayout className={s.page}>
        <Grid>
          <Row>
            <Col>
              <h1>{title}</h1>
              <h2>¯\_(ツ)_/¯</h2>
              <p>Sorry, the page you were trying to view does not exist</p>
            </Col>
          </Row>
        </Grid>
      </PageLayout>
    );
  }
}

export default withStyles(s)(NotFoundPage);
