import _formatDate from 'date-fns/format';
import IntlMessageFormat from 'intl-messageformat';
import { round } from 'qc-round';

import DateUtils from './DateUtils';

export const formatDate = _formatDate;

/**
 * Formats the given date as '1st', '2nd', etc.
 */
export const formatDay = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'Do';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'Monday', 'Sunday', etc.
 */
export const formatDayOfWeek = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'dddd';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date in `YYYY-MM-DDTHH:mm:ss` format which conforms to ISO 8601.
 */
export const formatIsoDate = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'YYYY-MM-DDTHH:mm:ss';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date in `YYYY-MM-DD` format which conforms to ISO 8601.
 */
export const formatIsoDateOnly = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'YYYY-MM-DD';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'Jan 1st', 'Jan 2nd', 'Dec 25th`, etc.
 */
export const formatMonAndDay = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'MMM Do';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'January', 'February', etc.
 */
export const formatMonth = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'MMMM';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'January 1st', 'January 2nd', 'December 25th`, etc.
 */
export const formatMonthAndDay = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'MMMM Do';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'January 1st, 2019', etc.
 */
export const formatMonthDayAndYear = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'MMMM Do, YYYY';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as 'January 1', 'January 2', 'December 25`, etc.
 */
export const formatMonthAndDayAlt = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const pattern = 'MMMM D';
  return _formatDate(date, pattern);
};

export const formatQuarterHour = dateLike => {
  const date = DateUtils.toNearestQuarterHour(DateUtils.toDate(dateLike));

  const pattern = 'HH:mm';
  return _formatDate(date, pattern);
};

/**
 * Formats the given date as `hh a`, `hh:mm a`, or `hh:mm:ss a` depending on value
 * of minutes and seconds.  For example: '7 am', '7:45 am', '11:10 am', '1 pm',
 * '11:42:48 pm'.
 */
export const formatTime = dateLike => {
  const date = DateUtils.toDate(dateLike);
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();
  //
  // let pattern = 'h:mm:ss a';
  // if (seconds === 0) {
  //   if (minutes === 0) {
  //     pattern = 'h a';
  //   } else {
  //     pattern = 'h:mm a';
  //   }
  // }
  // return _formatDate(date, pattern);

  let pattern = 'h:mm a';
  if (minutes === 0) {
    pattern = 'h a';
  } else {
    pattern = 'h:mm a';
  }
  return _formatDate(date, pattern);
};

const usdMsg = new IntlMessageFormat('{amount, number, USD}', 'en', {
  number: {
    USD: {
      style: 'currency',
      currency: 'USD',
    },
  },
});

const EndInZeroCents = /\.0*$/;

export const formatUsd = amount => {
  let currency = usdMsg.format({ amount });
  currency = currency.replace(EndInZeroCents, '');
  return currency;
};

const enMiles = new IntlMessageFormat(
  '{value, plural, =1 {1 mile} other {# miles}}',
  'en',
);

export const formatMiles = distance => {
  const value = round(distance);
  const output = enMiles.format({ value });
  return output;
};

const enNumber = new IntlMessageFormat('{value, number}', 'en');

export const formatNumber = value => {
  const output = enNumber.format({ value });
  return output;
};

export const format24To12Hour = value => {
  const split = value.split(':');
  const hour = parseInt(split[0], 10);
  const minute = parseInt(split[1], 10);
  if (hour > 12) {
    return `${hour - 12}:${minute === 0 ? '00' : minute} pm`;
  }
  return `${hour}:${minute === 0 ? '00' : minute} am`;
};
