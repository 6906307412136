import React from 'react';
import ContentLoader from 'react-content-loader';

const AreaOfExpertiseBoxLoader = props => (
  <ContentLoader
    height={300}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    speed={4}
    width={300}
    {...props}
  >
    <rect height="14" rx="4" ry="4" width="140" x="80" y="224" />
    <rect height="12" rx="3" ry="3" width="92" x="104" y="249" />
    <rect height="12" rx="3" ry="3" width="120" x="50" y="270" />
    <circle cx="180" cy="276" r="2" />
    <rect height="12" rx="3" ry="3" width="68" x="190" y="270" />
    <circle cx="150" cy="108" r="100" />
  </ContentLoader>
);

export default AreaOfExpertiseBoxLoader;
