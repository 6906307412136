exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2N4Zy{margin:10px;text-align:center}._2G5Nb{font-size:12.8px;font-size:.8rem}._2G5Nb::before{content:\"\\2022\";padding:0 6px}._36B5T{position:absolute;right:0;bottom:0;background-color:rgba(0,0,0,0);border:0;margin:6px}._2ViRd{color:var(--primary--400);color:var(--primary--400);opacity:0;font-size:20.8px;font-size:1.3rem;-webkit-transition:var(--base--transition);transition:var(--base--transition);transition:var(--base--transition)}._2ViRd:active{-webkit-transform:scale(0.75);-ms-transform:scale(0.75);transform:scale(0.75)}._1YnL5{max-height:400px;min-height:160px;min-width:160px;max-width:200px;width:100%}.l1FWM{display:block;font-size:19.2px;font-size:1.2rem;color:var(--primary--600);color:var(--primary--600)}._1RJ5r{display:block;font-size:14.4px;font-size:.9rem}.vnrJH{position:relative;display:block;padding:6px}.vnrJH:hover ._2ViRd{color:var(--primary--400);color:var(--primary--400);opacity:1}", ""]);

// exports
exports.locals = {
	"root": "_2N4Zy",
	"distance": "_2G5Nb",
	"favButton": "_36B5T",
	"favIcon": "_2ViRd",
	"profilePhoto": "_1YnL5",
	"displayName": "l1FWM",
	"pricePerSession": "_1RJ5r",
	"link": "vnrJH"
};