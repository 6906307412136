import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import FavoriteIcon from '../FavoriteIcon';
import Link from '../Link';
import RatingInfo from '../RatingInfo';

import FavoriteTypes from '../../lib/FavoriteTypes';
import { formatMiles } from '../../lib/FormatUtils';
import ImgShape from '../../lib/ImgShape';
import LinkShape from '../../lib/LinkShape';
import RatingInfoShape from '../../lib/RatingInfoShape';

import CostRangeUsd from '../CostRangeUsd';

import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';

import s from './ExpertBox.scss';

/*
Note: There is an ExpertBoxLoader component that is designed to basically be a
placeholder of this component while the data is loading.
*/
function ExpertBox(props) {
  const {
    distanceMi,
    expert,
    favorite,
    onFavorite,
    favoriteQueryVariables,
  } = props;

  const {
    displayName,
    link,
    profilePhoto,
    ratingInfo,
    upcomingSessionsInfo,
  } = expert;

  let costRangeUsd;
  if (upcomingSessionsInfo) {
    costRangeUsd = upcomingSessionsInfo.costRangeUsd;
  }

  return (
    <div className={s.root}>
      <Link className={s.link} {...link}>
        <ProfilePhoto
          alt={profilePhoto && profilePhoto.alt}
          className={s.profilePhoto}
          height={200}
          userProfile={expert}
          width={200}
        />
        {/* <img
            alt={profilePhoto.alt}
            className={s.profilePhoto}
            {...profilePhoto}
          />*/}
        {typeof favorite === 'boolean' && (
          <button
            className={s.favButton}
            onClick={evt => {
              // Note: Because this button is embedded within a link, we still have to prevent the
              // default for some reason even though we are attempting to stopping the click event
              // from propagating to the link.
              evt.preventDefault();
              evt.stopPropagation();
              onFavorite(
                FavoriteTypes.EXPERT.code,
                {
                  favoriteId: expert.userId,
                  newValue: !favorite,
                },
                favoriteQueryVariables,
              );
            }}
            type="button"
          >
            <FavoriteIcon className={s.favIcon} favorite={favorite} />
          </button>
        )}
      </Link>
      <span className={s.displayName}>{displayName}</span>
      {costRangeUsd && (
        <span className={s.pricePerSession}>
          <CostRangeUsd costRange={costRangeUsd} /> per session
        </span>
      )}
      <RatingInfo ratingInfo={ratingInfo || { count: 0, score: null }} />
      {typeof distanceMi === 'number' && (
        <span className={s.distance}>{formatMiles(distanceMi)}</span>
      )}
    </div>
  );
}

ExpertBox.propTypes = {
  distanceMi: PTs.number,
  expert: PTs.shape({
    displayName: PTs.string.isRequired,
    link: LinkShape.isRequired,
    profilePhoto: ImgShape,
    ratingInfo: RatingInfoShape,
    upcomingSessionsInfo: PTs.shape({
      costRangeUsd: PTs.shape({
        max: PTs.number,
        min: PTs.number,
      }),
    }),
    userId: PTs.string.isRequired,
  }).isRequired,
  favorite: PTs.bool,
  favoriteQueryVariables: PTs.shape({
    limit: PTs.number,
    predicates: PTs.shape({
      isExpert: PTs.bool,
      lat: PTs.shape({
        gte: PTs.number,
        lte: PTs.number,
      }),
      lng: PTs.shape({
        gte: PTs.number,
        lte: PTs.number,
      }),
    }),
  }),
  onFavorite: PTs.func.isRequired,
};

ExpertBox.defaultProps = {
  distanceMi: null,
  favorite: null,
  favoriteQueryVariables: {},
};

export default withStyles(s)(ExpertBox);
