import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import ShadowingBoxLoader from './ShadowingBoxLoader';

import s from './ShadowingBoxGridLoader.scss';

function ShadowingBoxGridLoader(props) {
  const listItems = [];
  for (let i = 0, iLen = props.count; i < iLen; ++i) {
    listItems.push(
      <li key={i}>
        <ShadowingBoxLoader />
      </li>,
    );
  }
  return (
    <ul className={s.grid} role="presentation">
      {listItems}
    </ul>
  );
}

ShadowingBoxGridLoader.propTypes = {
  count: PTs.number,
};

ShadowingBoxGridLoader.defaultProps = {
  count: 12,
};

export default withStyles(s)(ShadowingBoxGridLoader);
