/* eslint-disable max-len */
const connections = [
  {
    key: 'experts',
    img: {
      alt: 'Bridge at night',
      src: '/assets/images/home/icons/find-experts-to-shadow.svg',
    },
    text:
      'Go beyond textbooks and video tutorials. Shadow experts in fields ranging from music, art, engineering, sports and much more.',
    title: 'Find experts to shadow',
    link: 'shadowings/areas-of-expertise/',
    buttonText: 'Find an expert',
  },
  {
    key: 'you',
    img: {
      alt: 'DJ',
      src: '/assets/images/home/icons/let-people-shadow-you.svg',
    },
    text:
      'Share your professional knowledge, talent or expertise with the world. Schedule shadowers on your time and set your own price.',
    title: 'Let people shadow you',
    link: 'handbook/',
    buttonText: 'Get started',
  },
];

export default connections;
