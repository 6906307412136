import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../../components/Link';

import ConnectionShape from '../../../lib/ConnectionShape';

import s from './ConnectionBox.scss';

class ConnectionBox extends React.Component {
  static propTypes = {
    connection: ConnectionShape.isRequired,
  };

  render() {
    const { connection } = this.props;
    const { img, text, title, link, buttonText } = connection;
    return (
      <div className={s.root}>
        <img alt={img.alt} className={s.img} {...img} />
        <div>
          <h3 className={s.title}>{title}</h3>
          <p className={s.description}>{text}</p>

          <Link className={s.link} to={link}>
            {buttonText}
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ConnectionBox);
