exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3T_3U{position:relative;margin:20px;padding:20px;border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-webkit-transition:.3s ease;transition:.3s ease;-ms-flex:1 1;flex:1 1;background:#fff;-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);overflow:hidden}._1kOLp{border-radius:50%;max-width:120px;max-height:120px;width:20%;margin:0 20px 0 -30px}.ZOqpm{color:var(--neutral--900);color:var(--neutral--900);font-weight:600;font-size:20px;margin:0 0 var(--block--spacing--xs) 0;margin:0 0 var(--block--spacing--xs) 0;letter-spacing:1px}._3RuO-{color:var(--neutral--700);color:var(--neutral--700);margin-bottom:var(--block--spacing--sm);margin-bottom:var(--block--spacing--sm)}._3VyIg{font-size:18px}._3VyIg svg{margin-left:5px}", ""]);

// exports
exports.locals = {
	"root": "_3T_3U",
	"img": "_1kOLp",
	"title": "ZOqpm",
	"description": "_3RuO-",
	"link": "_3VyIg"
};