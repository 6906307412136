exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1m0wk{position:relative;float:left;width:calc(100% + 20px);left:-10px}._1m0wk li{float:left;width:100%}@media(min-width: 576px){._1m0wk li{width:50%}}@media(min-width: 768px){._1m0wk li{width:33.333%}}@media(min-width: 992px){._1m0wk li{width:25%}}", ""]);

// exports
exports.locals = {
	"grid": "_1m0wk"
};