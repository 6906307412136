import PTs from 'prop-types';

import ImgShape from './ImgShape';

const ConnectionShape = PTs.shape({
  buttonText: PTs.string.isRequired,
  img: ImgShape.isRequired,
  key: PTs.string.isRequired,
  text: PTs.string.isRequired,
  title: PTs.string.isRequired,
});

export default ConnectionShape;
