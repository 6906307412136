exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Ewqm{color:var(--primary--400) !important;color:var(--primary--400) !important;opacity:1 !important;font-size:20.8px !important;font-size:1.3rem !important}", ""]);

// exports
exports.locals = {
	"favorited": "_1Ewqm"
};