import PTs from 'prop-types';

import ExpertProfileSummaryShape from './ExpertProfileSummaryShape';
import ImgShape from './ImgShape';
import LinkShape from './LinkShape';
import RatingInfoShape from './RatingInfoShape';

const ShadowingSummaryShape = PTs.shape({
  /**
   * The approximate distance the user is to this session.
   */
  distance: PTs.number,
  /**
   * The expert profile associated with this session.
   */
  expert: ExpertProfileSummaryShape.isRequired,
  /**
   * Flag indicating that this session is one of the currently logged in user's
   * favorites.
   */
  favorite: PTs.bool.isRequired,
  /**
   * A photo representation of this session.
   */
  img: ImgShape.isRequired,
  /**
   * The canonical link to this session's details page.
   */
  link: LinkShape.isRequired,
  /**
   * The price per session in the current user's locale. May be `null`. This can
   * happen when there are no upcoming sessions.
   */
  pricePerSession: PTs.string,
  /**
   * The rating information for this session.
   */
  ratingInfo: RatingInfoShape,
  /**
   * The title of this session.
   */
  title: PTs.string.isRequired,
});

export default ShadowingSummaryShape;
