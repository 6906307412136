import PTs from 'prop-types';

const RatingInfoShape = PTs.shape({
  /**
   * The total number of unique rating scores.
   */
  count: PTs.number.isRequired,
  /**
   * The average score out of 5. Is `null` when `count` is zero.
   */
  score: PTs.number,
});

export default RatingInfoShape;
