/* eslint-disable no-nested-ternary */
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';
import fontAwesomeStyles from '@fortawesome/fontawesome-free/css/all.css';

import NullRatingInfo from '../../lib/NullRatingInfo';
import RatingInfoShape from '../../lib/RatingInfoShape';

import s from './RatingInfo.scss';

class RatingInfo extends React.Component {
  static propTypes = {
    ratingInfo: RatingInfoShape,
  };

  static defaultProps = {
    ratingInfo: NullRatingInfo,
  };

  render() {
    const { ratingInfo } = this.props;
    const { count, score = 0 } = ratingInfo || NullRatingInfo; // Necessary if rating is `null`.
    const icon2 = score < 1.25 ? 'empty' : score < 1.75 ? 'half' : 'full';
    const icon3 = score < 2.25 ? 'empty' : score < 2.75 ? 'half' : 'full';
    const icon4 = score < 3.25 ? 'empty' : score < 3.75 ? 'half' : 'full';
    const icon5 = score < 4.25 ? 'empty' : score < 4.75 ? 'half' : 'full';
    return (
      <span className={s.ratingInfo}>
        <span className={s.icons}>
          {/*
          Note: The following was done this way instead like how the 4 other stars are
          done to prevent eslint from complaining about unused classes
          */}
          <i
            className={score < 0.25 ? s.empty : score < 0.75 ? s.half : s.full}
          />
          <i className={s[icon2]} />
          <i className={s[icon3]} />
          <i className={s[icon4]} />
          <i className={s[icon5]} />
        </span>
        <span className={s.count}>{count}</span>
      </span>
    );
  }
}

export default withStyles(fontAwesomeStyles, s)(RatingInfo);
