import React from 'react';

import NotFoundPage from './NotFoundPage';

const title = 'Page Not Found';

function action() {
  return {
    chunks: ['initial'],
    title,
    component: <NotFoundPage title={title} />,
    status: 404,
  };
}

export default action;
