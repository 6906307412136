import withStyles from 'isomorphic-style-loader/lib/withStyles';

import PTs from 'prop-types';
import React from 'react';

import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import s from './FavoriteIcon.scss';

class FavoriteIcon extends React.Component {
  static propTypes = {
    className: PTs.string,
    favorite: PTs.bool.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, favorite } = this.props;
    if (favorite) {
      return <FontAwesomeIcon className={s.favorited} icon={fasHeart} />;
    }
    return <FontAwesomeIcon className={className} icon={faHeart} />;
  }
}

export default withStyles(s)(FavoriteIcon);
