exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NEG3W h1, .NEG3W h2, .NEG3W p{text-align:center;color:var(--neutral-500);color:var(--neutral-500)}", ""]);

// exports
exports.locals = {
	"page": "NEG3W"
};