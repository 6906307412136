import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import FavoriteIcon from '../FavoriteIcon';
import Link from '../Link';
import ProfilePhoto from '../ProfilePhoto';
import RatingInfo from '../RatingInfo';

import FavoriteTypes from '../../lib/FavoriteTypes';
import { formatMiles } from '../../lib/FormatUtils';
// import ShadowingShape from '../../lib/ShadowingShape';
import ShadowingSummaryShape from '../../lib/ShadowingSummaryShape';

// import CostRangeUsd from '../CostRangeUsd';

import s from './ShadowingSummaryBox.scss';

class ShadowingSummaryBox extends React.Component {
  static propTypes = {
    // currentUserToShadowing: PTs.shape({
    //   distanceMi: PTs.number,
    //   favorite: PTs.bool,
    //   shadowing: ShadowingShape,
    // }),
    favoriteQueryVariables: PTs.shape({
      limit: PTs.number,
      predicates: PTs.shape({
        lat: PTs.shape({
          gte: PTs.number,
          lte: PTs.number,
        }),
        lng: PTs.shape({
          gte: PTs.number,
          lte: PTs.number,
        }),
      }),
    }),
    hideNullRating: PTs.bool,
    onFavorite: PTs.func,
    shadowingSummary: ShadowingSummaryShape,
  };

  static defaultProps = {
    // currentUserToShadowing: null,
    favoriteQueryVariables: {},
    hideNullRating: false,
    onFavorite: null,
    shadowingSummary: null,
  };

  render() {
    // const { currentUserToShadowing } = this.props;
    const {
      hideNullRating,
      onFavorite,
      shadowingSummary,
      favoriteQueryVariables,
    } = this.props;

    // if (currentUserToShadowing) {
    //   const { distanceMi, favorite, shadowing } = currentUserToShadowing;
    //   const {
    //     expert,
    //     img,
    //     link,
    //     name,
    //     ratingInfo,
    //     upcomingSessionsInfo,
    //   } = shadowing;
    //   const { costRangeUsd } = upcomingSessionsInfo;
    //   return (
    //     <div className={s.root}>
    //       <Link className={s.link} {...link}>
    //         <div className={s.expertRoot}>
    //           <ProfilePhoto height={40} userProfile={expert} width={40} />
    //           <span className={s.expertDisplayName}>{expert.displayName}</span>
    //         </div>
    //         <div className={s.overlay} />
    //         <img alt={img.alt} className={s.img} {...img} />
    //         {onFavorite &&
    //           typeof favorite === 'boolean' && (
    //             <button
    //               className={s.favButton}
    //               type="button"
    //               onClick={evt => {
    //                 // Note: Because this button is embedded within a link, we still have to prevent the
    //                 // default for some reason even though we are attempting to stopping the click event
    //                 // from propagating to the link.
    //                 evt.preventDefault();
    //                 evt.stopPropagation();
    //                 onFavorite(FavoriteTypes.SHADOWING.code, {
    //                   favoriteId: shadowing.id,
    //                   newValue: !favorite,
    //                 });
    //               }}
    //             >
    //               <FavoriteIcon className={s.favIcon} favorite={favorite} />
    //             </button>
    //           )}
    //       </Link>
    //       <span className={s.displayName}>{name}</span>
    //       <span className={s.pricePerSession}>
    //         <CostRangeUsd costRange={costRangeUsd} />
    //       </span>
    //       <RatingInfo ratingInfo={ratingInfo} />
    //       {typeof distanceMi === 'number' && (
    //         <span className={s.distance}>{distanceMi} miles</span>
    //       )}
    //     </div>
    //   );
    // }

    const {
      distance,
      expert,
      favorite,
      img,
      link,
      pricePerSession,
      ratingInfo,
      title,
    } = shadowingSummary;
    const userProfile = {
      ...expert,
    };
    const isNullRating = ((ratingInfo || {}).count || 0) === 0;
    return (
      <div className={s.root}>
        <Link className={s.link} {...link}>
          <div className={s.expertRoot}>
            <ProfilePhoto height={40} userProfile={userProfile} width={40} />
            <span className={s.expertDisplayName}>{expert.displayName}</span>
          </div>
          <div className={s.overlay} />
          {/* <img alt={img.alt} className={s.img} {...img} /> */}
          <div className={s.imgContainer}>
            <div
              className={s.img}
              style={{ backgroundImage: `url(${img.src})` }}
            />
          </div>

          {onFavorite && typeof favorite === 'boolean' && (
            <button
              className={s.favButton}
              onClick={evt => {
                // Note: Because this button is embedded within a link, we still have to prevent the
                // default for some reason even though we are attempting to stopping the click event
                // from propagating to the link.
                evt.preventDefault();
                evt.stopPropagation();
                onFavorite(
                  FavoriteTypes.SHADOWING.code,
                  {
                    favoriteId: shadowingSummary.id,
                    newValue: !favorite,
                  },
                  favoriteQueryVariables,
                );
              }}
              type="button"
            >
              <FavoriteIcon className={s.favIcon} favorite={favorite} />
            </button>
          )}
        </Link>
        <span className={s.displayName}>{title}</span>
        <span className={s.pricePerSession}>{pricePerSession}</span>
        {isNullRating && hideNullRating ? null : (
          <RatingInfo ratingInfo={ratingInfo} />
        )}
        {typeof distance === 'number' && (
          <span className={s.distance}>{formatMiles(distance)}</span>
        )}
      </div>
    );
  }
}

export default withStyles(s)(ShadowingSummaryBox);

/*
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import FavoriteIcon from '../FavoriteIcon';
import Link from '../Link';
import ProfilePhoto from '../ProfilePhoto';
import RatingInfo from '../RatingInfo';

import ShadowingShape from '../../lib/ShadowingShape';
import ShadowingSummaryShape from '../../lib/ShadowingSummaryShape';

import CostRangeUsd from '../CostRangeUsd';

import s from './ShadowingSummaryBox.scss';

class ShadowingSummaryBox extends React.Component {
  static propTypes = {
    currentUsersShadowingEdge: PTs.shape({
      distanceMi: PTs.number,
      favorite: PTs.bool,
      shadowing: ShadowingShape,
    }),
    shadowingSummary: ShadowingSummaryShape,
  };

  static defaultProps = {
    currentUsersShadowingEdge: null,
    shadowingSummary: null,
  };

  render() {
    const { distanceMi, favorite, record, img, ratingInfo } = this.props;

    const { name, slug, expert } = record;

    const link = `/shadowings/~${slug}`;
    const costRangeUsd = { min: 25, max: 40 };
    return (
      <div className={s.root}>
        <Link className={s.link} to={link}>
          <div className={s.expertRoot}>
            <ProfilePhoto height={40} userProfile={expert} width={40} />
            <span className={s.expertDisplayName}>{expert.displayName}</span>
          </div>
          <div className={s.overlay} />
          <img alt={img.alt} className={s.img} {...img} />
          <FavoriteIcon className={s.favIcon} favorite={favorite} />
        </Link>
        <span className={s.displayName}>{name}</span>
        <span className={s.pricePerSession}>
          <CostRangeUsd costRange={costRangeUsd} />
        </span>
        <RatingInfo ratingInfo={ratingInfo} />
        <span className={s.distance}>{distanceMi} miles</span>
      </div>
    );
  }
}

export default withStyles(s)(ShadowingSummaryBox);
*/
