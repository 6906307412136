import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';

import Link from '../Link';

import AreaOfExpertiseShape from '../../lib/AreaOfExpertiseShape';

import s from './AreaOfExpertiseBox.scss';

/*
Note: There is an AreaOfExpertiseBoxLoader component that is designed to
basically be a placeholder of this component while the data is loading.
*/
class AreaOfExpertiseBox extends React.Component {
  static propTypes = {
    areaOfExpertise: AreaOfExpertiseShape.isRequired,
  };

  render() {
    const { areaOfExpertise } = this.props;
    const { img, link, text, title } = areaOfExpertise;
    const { ...imgProps } = img;

    delete imgProps.altMsgId;
    return (
      <div className={s.root}>
        <Link className={s.link} {...link}>
          <span className={s.title}>{title}</span>
          <div className={s.overlay} />
          <img alt={img.alt} className={s.img} {...imgProps} />
        </Link>
        <p className={s.text}>{text}</p>
      </div>
    );
  }
}

export default withStyles(s)(AreaOfExpertiseBox);
