/* eslint-disable import/prefer-default-export */

import { SET_POPULAR_SESSION_LOCATIONS } from './';

export function setPopularSessionLocations(popularSessionLocations) {
  return {
    type: SET_POPULAR_SESSION_LOCATIONS,
    payload: {
      popularSessionLocations,
    },
  };
}
