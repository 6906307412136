import PTs from 'prop-types';

import ImgShape from './ImgShape';
import LinkShape from './LinkShape';

const AreaOfExpertiseShape = PTs.shape({
  desc: PTs.string,
  descMsgId: PTs.string,
  expertCount: PTs.number,
  favorite: PTs.bool,
  id: PTs.string,
  img: ImgShape,
  link: LinkShape,
  text: PTs.node,
  title: PTs.node,
  titleMsgId: PTs.node,
});

export default AreaOfExpertiseShape;
