import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

// import CurrentUserToShadowingsInfoShape from '../../lib/CurrentUserToShadowingsInfoShape';
import ShadowingSummaryShape from '../../lib/ShadowingSummaryShape';

import ShadowingSummaryBox from '../ShadowingSummaryBox';

import s from './ShadowingSummaryBoxGrid.scss';

function ShadowingSummaryBoxGrid(props) {
  const { hideNullRatings, onFavorite, favoriteQueryVariables } = props;

  // if (props.currentUserToShadowingsInfo) {
  //   return (
  //     <ul className={s.grid} role="presentation">
  //       {props.currentUserToShadowingsInfo.list.map(item => (
  //         <li key={item.shadowing.shadowingId}>
  //           <ShadowingSummaryBox
  //             currentUserToShadowing={item}
  //             hideNullRating={hideNullRatings}
  //             onFavorite={onFavorite}
  //           />
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // }
  return (
    <ul className={s.grid} role="presentation">
      {props.shadowingSummaries.map(ss => (
        <li key={ss.id}>
          <ShadowingSummaryBox
            favoriteQueryVariables={favoriteQueryVariables}
            hideNullRating={hideNullRatings}
            onFavorite={onFavorite}
            shadowingSummary={ss}
          />
        </li>
      ))}
    </ul>
  );
}

ShadowingSummaryBoxGrid.propTypes = {
  // currentUserToShadowingsInfo: CurrentUserToShadowingsInfoShape,
  favoriteQueryVariables: PTs.shape({
    limit: PTs.number,
    predicates: PTs.shape({
      lat: PTs.shape({
        gte: PTs.number,
        lte: PTs.number,
      }),
      lng: PTs.shape({
        gte: PTs.number,
        lte: PTs.number,
      }),
    }),
  }),
  hideNullRatings: PTs.bool,
  onFavorite: PTs.func,
  shadowingSummaries: PTs.arrayOf(ShadowingSummaryShape),
};

ShadowingSummaryBoxGrid.defaultProps = {
  // currentUserToShadowingsInfo: null,
  favoriteQueryVariables: {},
  hideNullRatings: false,
  onFavorite: null,
  shadowingSummaries: null,
};

export default withStyles(s)(ShadowingSummaryBoxGrid);
