const opportunities_by_skill_level = {
  novice: [
    { id: 'a', name: 'Explore potential careers' },
    { id: 'b', name: 'Discover new hobbies' },
    { id: 'c', name: 'Find your next passion' },
  ],
  expert: [
    { id: 'a', name: 'Earn extra income' },
    { id: 'b', name: 'Expand your business' },
    { id: 'c', name: 'Share your passions' },
  ],
  amateur: [
    { id: 'a', name: 'Deepen your skillset' },
    { id: 'b', name: 'Gain real-life experience' },
    { id: 'c', name: 'Build connections' },
  ],
};

export default opportunities_by_skill_level;
